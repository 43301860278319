const Twitter = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0ZM16.2432 8.17345C16.7254 8.11733 17.1841 7.99318 17.6111 7.80944L17.6101 7.81093C17.2904 8.2752 16.8882 8.68048 16.4222 9.00775C16.4268 9.10708 16.4293 9.20739 16.4293 9.30671C16.4293 12.3638 14.0336 15.8888 9.65116 15.8888C8.35656 15.8902 7.08894 15.5293 6 14.8494C6.18873 14.8709 6.37861 14.8817 6.56864 14.8816C7.64143 14.8832 8.68357 14.5342 9.52741 13.8909C9.03032 13.8821 8.54852 13.7225 8.14947 13.4345C7.75041 13.1465 7.45407 12.7445 7.30194 12.2849C7.65905 12.351 8.02695 12.3374 8.37786 12.2451C7.83866 12.1393 7.35376 11.8556 7.00544 11.4421C6.65712 11.0286 6.46684 10.5109 6.46688 9.97664V9.94684C6.79767 10.1254 7.16759 10.2247 7.54586 10.2364C7.21969 10.0248 6.95233 9.73838 6.7675 9.40242C6.58267 9.06647 6.48608 8.69141 6.48631 8.31052C6.48631 7.8874 6.60392 7.49012 6.80847 7.14746C7.40669 7.86216 8.15297 8.44672 8.99888 8.86319C9.84478 9.27967 10.7714 9.51876 11.7186 9.56495C11.5987 9.06871 11.6509 8.54792 11.867 8.08342C12.0831 7.61892 12.4511 7.2367 12.9138 6.99612C13.3765 6.75553 13.9081 6.67003 14.426 6.75289C14.9438 6.83575 15.4191 7.08233 15.7779 7.45436C16.3114 7.35265 16.823 7.16285 17.2905 6.89319C17.113 7.42927 16.7408 7.88432 16.2432 8.17345Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Twitter;
